var render = function () {
  var _vm$meData$agency, _vm$meData$agency2, _vm$meData$agency3, _vm$meData$agency4;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "navbar-container d-flex content align-items-center px-1 py-50",
    style: "".concat(_vm.navBarBackgroundColor ? "background-color: ".concat(_vm.navBarBackgroundColor, " !important") : '')
  }, [_c('ul', {
    staticClass: "nav navbar-nav"
  }, [_c('li', {
    staticClass: "nav-item d-flex"
  }, [_c('b-link', {
    staticClass: "nav-link d-flex d-xl-none",
    class: _vm.isMobileView ? 'align-items-center' : '',
    on: {
      "click": _vm.toggleVerticalMenuActive
    }
  }, [_c('feather-icon', {
    staticClass: "d-inline",
    attrs: {
      "icon": "MenuIcon",
      "size": "21"
    }
  })], 1)], 1)]), _c('div', {
    staticClass: "bookmark-wrapper align-items-center flex-grow-1 d-none d-md-flex"
  }, [_vm.meData ? _c('div', {
    staticClass: "d-flex flex-row"
  }, [_c('div', {
    staticClass: "d-flex-center justify-content-start text-uppercase"
  }, [_c('span', {
    staticClass: "font-weight-bold d-block text-nowrap text-white pb-0 mr-50"
  }, [_vm._v(" " + _vm._s(_vm.meData.agency.agencyName) + " ")]), _c('em', {
    staticClass: "font-weight-bold text-warning text-nowrap",
    on: {
      "dblclick": _vm.onHiddenClickHandle
    }
  }, [_vm._v("(" + _vm._s(_vm.meData.agency.agencyCode) + ")")])]), !_vm.isRoleF1 ? _c('div', {
    staticClass: "d-flex-center gap-2 text-white ml-50"
  }, [_c('em', {
    class: "".concat(_vm.textBalanceClass, " ").concat(_vm.appBreakPoint === 'lg' ? 'font-small-3' : '')
  }, [_c('span', {
    staticClass: "d-none d-lg-inline"
  }, [_vm._v(_vm._s(_vm.$t('accountsPayableTemp')) + ":")]), _c('em', [_vm._v(" " + _vm._s(_vm.formatCurrency(_vm.meData.agency.balance)) + " "), _c('span', {
    staticClass: "d-none d-lg-inline"
  }, [_vm._v("VND")])]), _c('em', {
    staticClass: "text-white text-nowrap"
  }, [_vm._v(" / " + _vm._s(_vm.formatCurrency(_vm.meData.agency.limitCredit)) + " "), _c('span', {
    staticClass: "d-none d-lg-inline"
  }, [_vm._v("VND")])])]), (_vm$meData$agency = _vm.meData.agency) !== null && _vm$meData$agency !== void 0 && _vm$meData$agency.lockBalance ? _c('em', {
    class: "text-danger ".concat(_vm.appBreakPoint === 'lg' ? 'font-small-3' : '')
  }, [_c('span', {
    staticClass: "d-none d-lg-inline"
  }, [_vm._v(_vm._s(_vm.$t('lockBalance')) + ":")]), _c('em', [_vm._v(" " + _vm._s(_vm.formatCurrency((_vm$meData$agency2 = _vm.meData.agency) === null || _vm$meData$agency2 === void 0 ? void 0 : _vm$meData$agency2.lockBalance)) + " "), _c('span', {
    staticClass: "d-none d-lg-inline"
  }, [_vm._v("VND")])])]) : _vm._e(), _c('BButton', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover.v-info",
      modifiers: {
        "hover": true,
        "v-info": true
      }
    }],
    staticClass: "p-25 rounded-circle",
    attrs: {
      "title": _vm.$t('reloadAccountsPayable'),
      "variant": "flat-primary"
    },
    on: {
      "click": _vm.handleReload
    }
  }, [_c('feather-icon', {
    staticClass: "text-white",
    class: _vm.loadingReload ? 'spinner-1s' : '',
    attrs: {
      "icon": "RotateCwIcon",
      "size": "16"
    }
  })], 1), _c('BButton', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover.v-info",
      modifiers: {
        "hover": true,
        "v-info": true
      }
    }],
    staticClass: "p-25 rounded-circle",
    attrs: {
      "title": _vm.$t('topup.createTopup'),
      "variant": "flat-primary"
    },
    on: {
      "click": _vm.handleCreateTopup
    }
  }, [_c('feather-icon', {
    staticClass: "text-white",
    attrs: {
      "icon": "DollarSignIcon",
      "size": "16"
    }
  })], 1)], 1) : _vm._e()]) : _vm._e()]), !_vm.isRoleF1 && _vm.meData ? _c('div', {
    staticClass: "d-flex-center d-md-none"
  }, [_c('div', {
    class: "".concat(_vm.textBalanceClass, " d-flex flex-wrap gap-1")
  }, [_c('em', {
    staticClass: "text-white text-nowrap mr-25 d-none d-sm-inline"
  }, [_vm._v(_vm._s(_vm.$t('accountsPayableTemp')) + ":")]), _vm.meData ? _c('div', {
    class: _vm.isMobileView ? 'font-small-2 d-flex-center flex-wrap gap-1' : '',
    staticStyle: {
      "line-height": "normal"
    }
  }, [_c('em', {
    class: _vm.isMobileView ? 'font-small-2 text-nowrap' : ''
  }, [_vm._v(" " + _vm._s(_vm.formatCurrency(_vm.meData.agency.balance)) + " ")]), _c('em', {
    staticClass: "text-white text-nowrap",
    class: _vm.isMobileView ? 'font-small-2' : ''
  }, [_vm._v("/ " + _vm._s(_vm.formatCurrency(_vm.meData.agency.limitCredit)) + " ")]), (_vm$meData$agency3 = _vm.meData.agency) !== null && _vm$meData$agency3 !== void 0 && _vm$meData$agency3.lockBalance ? _c('em', {
    staticClass: "text-danger"
  }, [_vm._v(" " + _vm._s(_vm.formatCurrency((_vm$meData$agency4 = _vm.meData.agency) === null || _vm$meData$agency4 === void 0 ? void 0 : _vm$meData$agency4.lockBalance)) + " ")]) : _vm._e()]) : _vm._e()]), _c('BButton', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover.v-info",
      modifiers: {
        "hover": true,
        "v-info": true
      }
    }],
    staticClass: "p-25 rounded-circle",
    attrs: {
      "title": _vm.$t('reloadAccountsPayable'),
      "variant": "flat-primary"
    },
    on: {
      "click": _vm.handleReload
    }
  }, [_c('feather-icon', {
    staticClass: "text-white",
    class: _vm.loadingReload ? 'spinner-1s' : '',
    attrs: {
      "icon": "RotateCwIcon",
      "size": "14"
    }
  })], 1), _c('BButton', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover.v-info",
      modifiers: {
        "hover": true,
        "v-info": true
      }
    }],
    staticClass: "p-25 rounded-circle",
    attrs: {
      "title": _vm.$t('topup.createTopup'),
      "variant": "flat-primary"
    },
    on: {
      "click": _vm.handleCreateTopup
    }
  }, [_c('feather-icon', {
    staticClass: "text-white",
    attrs: {
      "icon": "DollarSignIcon",
      "size": "14"
    }
  })], 1)], 1) : _vm._e(), _c('CreateTopupModal', {
    attrs: {
      "sender": _vm.senderData,
      "recipient": _vm.recipientData,
      "is-empty-bank-accounts": _vm.isEmptyBankAccounts
    },
    on: {
      "show": _vm.handleOpenTopupModal
    }
  }), _c('b-navbar-nav', {
    staticClass: "nav d-flex-center ml-auto flex-md-nowrap",
    class: _vm.isMobileView ? 'flex-nowrap' : ''
  }, [_c('BankSellingRate'), _c('locale'), _c('notification-dropdown'), _c('user-dropdown')], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }